import portalActions from '../actions/portals';
const ACTION_TYPES = portalActions.ACTION_TYPES;

export default function (state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.SHOW_LOADER:
        return {...state, ...{isLoading: true}}
    case ACTION_TYPES.DISMISS_LOADER:
        return {... state, ...{isLoading: false}}
    case ACTION_TYPES.SHOW_ALERT:
        return {... state, ...{isAlertOpen: true}, ...action.options}
    case ACTION_TYPES.CLOSE_ALERT:
        return {... state, ...{isAlertOpen: false}, ...action.options}
    case ACTION_TYPES.UPDATE_INPUT_VALUE:
        return {... state, ...{lastInputValue: action.value}}
    case ACTION_TYPES.SHOW_DATE_PICKER:
        return {... state, ...{isDatePickerOpen: true}, ...action.options}
    case ACTION_TYPES.CLOSE_DATE_PICKER:
        return {... state, ...{isDatePickerOpen: false}}
    case ACTION_TYPES.SHOW_VALUE_SELECTOR:
        return {... state, ...{isValueSelectorOpen: true}, ...action.options}
    case ACTION_TYPES.CLOSE_VALUE_SELECTOR:
        return {... state, ...{isValueSelectorOpen: false}}
    default:
        return state;
  }
}
