let PDFJS;
(async () => {
    if (typeof window !== "undefined" && "Worker" in window) {
        PDFJS = await import('pdfjs-dist/webpack');
    }
})();

const CONTACT_AVATAR_WIDTH = 100;

const makePathAbs = path => {
    return (path.match(/^https:\/\//) || path.match(/^http:\/\//) ? '' : 'https://') + path;
}

const imgAsBase64 = img => {
    let canvas = document.createElement('canvas');
    let base64Data;

    if (img.width < img.height) {
        width = CONTACT_AVATAR_WIDTH;
        height = Math.round(CONTACT_AVATAR_WIDTH * img.height / img.width);
    } else {
        height = CONTACT_AVATAR_WIDTH;
        width = Math.round(CONTACT_AVATAR_WIDTH * img.width / img.height);
    }

    canvas.width = width;
    canvas.height = height;

    context.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
    base64Data = canvas.toDataURL();

    return base64Data;
};

const readFileData = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsDataURL(file);
    });
};

const convertPdfToImages = async (link) => {
    const images = [];
    let blob;

    await fetch(link)
        .then(function (response) {
            return response.body;
        })
        .then(body => {
            const reader = body.getReader();

            return new ReadableStream({
                async start(controller) {
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            break;
                        }

                        controller.enqueue(value);
                    }

                  controller.close();
                  reader.releaseLock();
                }
            })
        })
        .then((rs) => new Response(rs))
        .then(async (response) => {
            blob = await response.blob();
        })
        .catch(function (error) {
            console.log(error);
        });

    const data = await readFileData(blob);
    const pdf = await PDFJS.getDocument(data).promise;
    const canvas = document.createElement("canvas");

    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 2.5 });
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport: viewport }).promise;

        images.push(canvas.toDataURL());
    }

    canvas.remove();
    return images;
};

const b64toBlob = (b64Data, type = 'image/png') => {
    const match = b64Data.split(',');
    const sliceSize = 512;
    const byteCharacters = atob(match[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays,{ type });
};

export default {
    makePathAbs,
    imgAsBase64,
    convertPdfToImages,
    b64toBlob,
}
