import { env } from '../config';
import { captureException, withScope } from '@sentry/browser';
import { parseServerError } from './api';

export default class handleError {
  static logError(error) {
    if (env !== 'development') {
      return withScope(scope => {
        scope.setExtra('parsed_error', parseServerError(error));
        captureException(error);
      });
    }
    console.error({ error });
  }
}
