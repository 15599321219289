import * as _ from 'lodash';
import portalActions from '../actions/portals';
const ACTION_TYPES = portalActions.ACTION_TYPES;

import reducers from '../reducers';
import initStore from './store';

let store;

function getStore() {
    return store || (store = initStore({}, reducers));
}

const showLoader = () => {
    getStore().dispatch({type: ACTION_TYPES.SHOW_LOADER})
}

const dismissLoader = () => {
    getStore().dispatch({type: ACTION_TYPES.DISMISS_LOADER})
}

const alert = options => {
    getStore().dispatch({
        type: ACTION_TYPES.SHOW_ALERT,
        options: options
    });
}

const alertShort = (message, okHandler = () => {}) => alert({
    content: message,
    buttons: [{
        name: 'Ok',
        handler: okHandler
    }]
});

const setLastInputValue = (value) => {
    getStore().dispatch({
        type: ACTION_TYPES.UPDATE_INPUT_VALUE,
        value
    })
}

const getLastInputValue = () => {
    return _.get(getStore().getState(), 'portals.lastInputValue');
}

const closeAlert = () => {
    getStore().dispatch({
        type: ACTION_TYPES.CLOSE_ALERT,
        options: {
            buttons: [],
            title: '',
            content: '',
            showCross: '',
            inputCaption: ''    
        }
    })
}

const selectDate = options => {
    getStore().dispatch({
        type: ACTION_TYPES.SHOW_DATE_PICKER,
        options: options
    });
}

const closeDatePicker = () => {
    getStore().dispatch({type: ACTION_TYPES.CLOSE_DATE_PICKER})
}

const selectValueFromList = options => {
    getStore().dispatch({
        type: ACTION_TYPES.SHOW_VALUE_SELECTOR,
        options: options
    });
}

const closeValueSelector = () => {
    getStore().dispatch({type: ACTION_TYPES.CLOSE_VALUE_SELECTOR});
}

export default {
    showLoader,
    dismissLoader,
    alert,
    alertShort,
    closeAlert,  
    selectDate,
    closeDatePicker,
    selectValueFromList,
    closeValueSelector,
    setLastInputValue,
    getLastInputValue,
}