/* eslint-disable react/sort-comp */

import React, { Component } from 'react';
import { Provider } from 'react-redux'
import reducers from '../reducers';
import initStore from '../utils/store';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import * as config from '../../src/config';
import DateTime from '../utils/date-time';
const env = config.default;
const dsn = env.sentry && env.sentry.dsn || false;
if (dsn) Sentry.init({ dsn });

const decorator = (ComposedComponent) => {
  class PageComponent extends Component {
    static async getInitialProps(ctx) {
      const { req } = ctx;
      const isServer = !!req;
      const platform = (req) ? 'server' : 'web';

      const store = initStore(reducers, {}, platform);

      let pageProps = {};

      if (ComposedComponent.getInitialProps) {
        pageProps = await ComposedComponent.getInitialProps(ctx, store);
      }

      return {
        ...pageProps,
        initialState: store.getState(),
        isServer,
        platform,
      };
    }

    componentDidCatch(error, errorInfo) {
      if (Sentry && dsn) {
        Sentry.captureException({ error, errorInfo });
      }
      console.error({ error, errorInfo });
    }

    constructor(props) {
      super(props);
      this.store = initStore(reducers, props.initialState, props.platform);
    }

    componentDidMount() {
      const timeFormat = localStorage.getItem('time_format');
      const browserLocale = localStorage.getItem('browser_locale');
      const locale = navigator.language;
      if (!timeFormat || browserLocale !== locale) {
        const timeFormat = DateTime.getTimeFormatByLocale();
        const locale = navigator.language;
        localStorage.setItem('time_format', timeFormat);
        localStorage.setItem('browser_locale', locale);
      }
    }

    render() {
      return (
        <Provider store={this.store}>
          <ComposedComponent {...this.props} />
        </Provider>
      );
    }
  }

  PageComponent.propTypes = {
    initialState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isServer: PropTypes.bool.isRequired,
    platform: PropTypes.string.isRequired,
  };

  return PageComponent;
};

export default decorator;
