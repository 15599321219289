export default {
  contentBackgroundBlue: '#ecf5fd',
  contentBackgroundGreen: '#efffee',
  contentBackgroundPink: '#fdecf7',
  contentBackgroundBrown: '#fdf5ec',
  buttonColorBlue: '#4ece3d',
  buttonColorGreen: '#3d87ce',
  indicatorColor: '#7ed321',
  headerColor: '#bb9d83',
  headerColorBlue: '#0079c4',
  headerColorGreen: '#2dc400',
  headerColorPink: '#c000c4',
  headerColorBrown: '#c47800',
  menuColorBlue: '#2c405a',
  menuColorGreen: '#375a2c',
  menuColorPink: '#5a2c53',
  menuColorBrown: '#5a4c2c',
  menuHoverBlue: '#3f536e',
  menuHoverGreen: '#3f6e3f',
  menuHoverPink: '#6d3f6e',
  menuHoverBrown: '#6e5d3f',
  buttonColorPink: '#3d87ce',
  buttonColorBrown: '#4ece3d',
  buttonColor: '#4ece3d',
  menuColor: '#2c405a',
  menuHighlighted: '#3f536e',
  iconColor: '#808080',
  inputTitle: '#9b9b9b',
  itemBgColor: '#fafafa',
  itemColor: '#4a4a4a',
  contactLineDark: '#ebebeb',
  frameColor: '#979797',
  tableFrameColor: '#e6e9ed',
  titleColor: '#707070',
  currentMonthColor: '#797979',
  codeVerTitle: '#8d8d8f',
  codeVerButton: '#0076ff',
  contactLineColor: '#999999',
  inputFrame: '#c5c5c5',
  inputLabel: '#656565',
  dateColor: '#cccccc',
  imageBg: '#f9fafb',
  infoColor: '#bfbdbf',
  infoCaptionText: '$40c1e2',
  serviceName: '#303133',
  imageSign: '#bbb8b8',
  alertContent: '#f0f0f0',
  warningBox: '#191919',
  inputHintBorder: '#d0021b',
  alertContentText: '#5a5a5a',
  checkBoxCaption: '#212b36',
  containerBgColor: '#f3f7fa',
  splitterFrame: '#dfe3e8',
  inputPlaceholder: '#b5b5b5',
  boxLabel: '#212b36',
  invoiceMonthTitle: '#818181',
  headerTextHover: '#1e1e1e',
  headerText: '#1e1e1e',
  landingHeaderTextHover: '#E74C3C',
  landingHeaderText: '#E74C3C',
  landingSearchText: '#777777',
  normalTextDark: '#4a4a4a',
  lightTextGrey: '#999999',
  normalTextGrey: '#808080',
  darkTextGrey: '#6f6f6f',
  bookingButtonColor: '#E74C3C',
  headerHighlightColor: '#E74C3C',
  headerHighlightColorHover: '#f93d00',
  intenseOrange: '#E3111A',
  greyBackground: '#f0f0f0',
  darkGreyBackground: '#ebebeb',
  lightBorder: '#dfdfdf',
  darkBorder: '#979797',
  darkIcon: '#8d8d8f',
  coloredIcon: '#eb5734',
  dropdownHover: '#FFE3DA',
  errorMessage: 'red',
};
