export default {
  USER_FETCH: 'USER_FETCH',
  USER_RECEIVE: 'USER_RECEIVE',
  USER_ERROR: 'USER_ERROR',
  USER_RESET: 'USER_RESET',
  USER_SET: 'USER_SET',

  AUTH_MODAL_CHANGE: 'AUTH_MODAL_CHANGE',

  SET_SIGN_UP_DATA: 'SET_SIGN_UP_DATA',
  SET_SPECIAL_SIGN_UP_DATA: 'SET_SPECIAL_SIGN_UP_DATA',

  APP_READY: 'APP_READY',

  LOGOUT: 'LOGOUT',
};
