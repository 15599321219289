import { combineReducers } from 'redux';

import app from './app';
import portals from './portals';
import user from './user';
import contact from './contact';
import invoice from './invoice';
import service from './service';

import {USER_ACTION_TYPES} from '../actions/user';
import { reducer as formReducer } from 'redux-form'


const rootReducer = combineReducers({
  app,
  portals,
  form: formReducer
});

const appReducer = (state, action) => {
  return rootReducer(state, action);
};

export default appReducer;
