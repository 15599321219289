import { publicCommonClient, commonClient, directoryClient, publicDirectoryClient } from './clients';
import {overwriteToken} from '../utils/api'

export const signUp = async (signUpData) => {
  const { data: tokenPair } = await publicCommonClient.post('/auth/signup', signUpData);

  return tokenPair;
};

export const specialSignUp = async (signUpData) => {
  const { data: tokenPair } = await publicCommonClient.post('/auth/specialSignup', signUpData);

  return tokenPair;
};

export const signIn = async (signInData) => {
  const { data: tokenPair } = await publicCommonClient.post('/auth/signin', signInData);

  return tokenPair;
};

export const sendActivationCode = async (userId) => {
  const { data } = await publicCommonClient.post('/auth/sendActivationCode', { userId });

  return data.success;
};

export const activateWithCode = async (data) => {
  const { data: tokenPair } = await publicCommonClient.post('/auth/activateWithCode', data);

  return tokenPair;
};

export const sendResetPasswordLink = async email => {
    return await publicCommonClient.post('/auth/sendReset', {email: email, isProvider: false});
}

export const resetPassword = async (password, token) => {
    overwriteToken(token);

    return await commonClient.post('/auth/resetPassword', { password })
        .then((response) => {
          localStorage.clear();
          return response.data;
        });
};

export const fetchMe = async () => {
  const { data: response } = await commonClient.get('/auth/getUserFromToken');

  return response.user;
};

export const checkSignUpKey = async (key) => {
  const { data: user } = await publicCommonClient.get(`/auth/specialSignup?key=${key}`);

  return user;
};

export const listing = async (providerType, serviceType, query, location, filterParams) => {
  const listing = await publicDirectoryClient.get(`/listing/${providerType}/${serviceType}/${query}`, {
    params: {
      location,
      filterParams,
    }
  });

  return listing;
};

export const getGooglePlaceDetailed = async (placeId) => {
  const { data: centre } = await publicDirectoryClient.get(`/listing/place/${placeId}`);

  return centre;
};

export const changePhone = async(userId, data) => {
  const { data: user } = await publicCommonClient.patch(`/auth/users/${userId}/changePhone`, data);

  return user;
};

export const getContactFromHash = async (hash) => {
  const { data: contact } = await publicCommonClient.get(`/contacts/hash/${hash}`);
  return contact;
};

export default {
  signUp,
  specialSignUp,
  signIn,
  sendActivationCode,
  activateWithCode,
  sendResetPasswordLink,
  resetPassword,
  fetchMe,
  checkSignUpKey,
  listing,
  getGooglePlaceDetailed,
  changePhone,
  getContactFromHash
};
