/* eslint-disable no-underscore-dangle */
/* global window, module */

import Immutable from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { env } from '../config';

function createMiddlewares({ isServer }) { // eslint-disable-line no-unused-vars
  const middlewares = [thunk];

  if (env === 'development' && typeof window !== 'undefined') {
    middlewares.push(createLogger({
      level: 'info',
      collapsed: true,
      stateTransformer: (state) => {
        const newState = {};

        for (const i of Object.keys(state)) { // eslint-disable-line no-restricted-syntax
          if (Immutable.Iterable.isIterable(state[i])) {
            newState[i] = state[i].toJS();
          } else {
            newState[i] = state[i];
          }
        }

        return newState;
      },
    }));
  }

  return middlewares;
}

function immutableChildren(obj) {
  const state = {};
  Object.keys(obj).map((key) => {
    state[key] = Immutable.fromJS(obj[key]);
    return undefined;
  });
  return state;
}

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

const initStore = (reducer, initialState, isServer) => {
  const middlewares = createMiddlewares({ isServer });
  const state = immutableChildren(initialState);

  if (isServer && typeof window === 'undefined') {
    return createStore(reducer, state, compose(applyMiddleware(...middlewares)));
  }

  if (!window[__NEXT_REDUX_STORE__]) {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      window[__NEXT_REDUX_STORE__] = createStore(reducer, state, compose(
        applyMiddleware(...middlewares),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
      ));
    } else {
      window[__NEXT_REDUX_STORE__] = createStore(reducer, state, applyMiddleware(...middlewares));
    }
  }

  if (module.hot) {
    module.hot.accept('../reducers', () =>
      window[__NEXT_REDUX_STORE__].replaceReducer(require('../reducers').default)); // eslint-disable-line global-require
  }

  return window[__NEXT_REDUX_STORE__];
};

export default initStore;
