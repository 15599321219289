import { SERVICE_ACTION_TYPES } from '../actions/services';
import serviceUtils from '../utils/service';

/**
 * @deprecated
 */
export default function (state = {}, action) {
    switch (action.type) {
        case SERVICE_ACTION_TYPES.FETCH_SERVICE_LIST:
            return {...state, ...{
                isSendingServiceList: true,
                isValidServiceList: false
            }};
        case SERVICE_ACTION_TYPES.RECEIVE_SERVICE_LIST:
            return {...state, ...{
                isSendingServiceList: false,
                isValidServiceList: true,
                serviceList: action.serviceList
            }};
        case SERVICE_ACTION_TYPES.STOP_SENDING_SERVICE_LIST: 
            return {...state, ...{isSendingServiceList: false}}
        case SERVICE_ACTION_TYPES.SELECT_SERVICE: 
            return {...state, ...{
                service: action.service,
                isValidService: true
            }};
        case SERVICE_ACTION_TYPES.RESET_SERVICE: 
            return {...state, ...{
                service: undefined,
                isValidService: false
            }};
        case SERVICE_ACTION_TYPES.SAVE_SERVICE: 
        case SERVICE_ACTION_TYPES.REMOVE_SERVICE: 
            return {...state, ...{
                isSendingService: true,
                isValidService: false,
                isServiceSaved: false
            }};
        case SERVICE_ACTION_TYPES.STOP_SENDING_SERVICE: 
            return {...state, ...{ isSendingService: false }};
        case SERVICE_ACTION_TYPES.RECEIVE_SERVICE:
            return {...state, ...{
                isSendingService: false,
                isValidService: true,
                isServiceSaved: true,
                service: action.service,
                serviceList: serviceUtils.reflectServiceInList(state, action.service)
            }};
        case SERVICE_ACTION_TYPES.CONFIRM_SERVICE_REMOVED:
            return {...state, ...{
                isSendingService: false,
                isValidService: false,                
                serviceList: serviceUtils.removeFromList(state, action.serviceId)
            }};
        default:
                return state;
    }
}
