import dateTime from './date-time';
import * as _ from 'lodash';
import imageUtils from './image';

const INVOICE_HEADER_STATE_MAP = state => {
    let contactState = state.contact;
    let userState = state.user;
    let invoiceState = state.invoice;

    return {
        contact: contactState.contact,
        number: _.get(userState, 'user.settings.invoiceNumber.prefix', '') + _.get(userState, 'user.settings.invoiceNumber.next', 1),
        invoiceHeader: invoiceState.invoiceHeader,
        isInvoiceHeaderFilled: invoiceState.isInvoiceHeaderFilled,
        isSendingUser: userState.isSendingUser
    }
}

const INVOICE_ITEMS_STATE_MAP = state => {
    let contactState = state.contact;
    let invoiceState = state.invoice;
    let serviceState = state.service;
    let userState = state.user;

    return {
        contact: contactState.contact,
        invoiceHeader: invoiceState.invoiceHeader,
        isInvoiceItemsFilled: invoiceState.isInvoiceItemsFilled,
        invoiceItems: invoiceState.invoiceItems,
        invoice: invoiceState.invoice,
        isSendingInvoice: invoiceState.isSendingInvoice,
        isInvoiceSaved: invoiceState.isInvoiceSaved,
        isSendingServiceList: serviceState.isSendingServiceList,
        isValidServiceList: serviceState.isValidServiceList,
        serviceList: serviceState.serviceList || [],
        currency: _.get(userState, 'user.settings.currency.value', '')
    }
}

const PREVIEW_STATE_MAP = state => {
    let contactState = state.contact;
    let invoiceState = state.invoice;

    return {
        contact: contactState.contact,
        invoice: invoiceState.invoice,
        isValidInvoice: invoiceState.isValidInvoice,
        isSendingInvoice: invoiceState.isSendingInvoice,
        isInvoiceSent: invoiceState.isInvoiceSent
    }
}

const DOCUMENTS_STATE_MAP = state => {
    let contactState = state.contact;
    let invoiceState = state.invoice;
    let userState = state.user;

    return {
        contact: contactState.contact,
        invoiceList: invoiceState.invoiceList,
        isValidInvoiceList: invoiceState.isValidInvoiceList,
        isSendingInvoiceList: invoiceState.isSendingInvoiceList,
        currency: _.get(userState, 'user.settings.currency.value', '')
    }
}

const getInvoicePostData = (invoiceHeader, invoiceItems) => {
    let invoiceDataToPost = {};

    Object.assign(invoiceDataToPost, _.clone(invoiceHeader));
    invoiceDataToPost.services = _.cloneDeep(invoiceItems);
    invoiceDataToPost.createdAt = dateTime.localDateAsISO(invoiceDataToPost.createdAt);
    invoiceDataToPost.services.forEach(el => el.date = dateTime.localDateAsISO(el.date));
    return invoiceDataToPost
}

function openInBrowser(invoices, justOpen = false) {
    let invoicePath, fileName;
    let TIME_LAG = 300;

    invoices.forEach((invoice, index) => {
        let link;

        if (invoicePath = _.get(invoice, 'pdfWithLogoTempLink', '')) {
            fileName = `invoice${invoice.number}-${Date.now()}.pdf`
            link = document.createElement('a');
            link.href = invoicePath;
            link.target = '_top';
            link.textContent = `Download ${fileName}`;
            if (!justOpen) {
                link.download = fileName;
            }
            setTimeout(() => link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window})),
                index * TIME_LAG
            )

        }
    });
}

const getFileNameFromPdf = (pdfFilename) => pdfFilename.split('.')[0];

const getInvoiceAsPng = async (link, fileName) => {
    const pdfFilename = fileName;
    const images = await imageUtils.convertPdfToImages(link);

    images.forEach((img) => {
        const href = img;
        const fileName = `${getFileNameFromPdf(pdfFilename)}.png`;
        const a = document.createElement('a');

        a.href = href;
        a.download = fileName;
        a.click();
    });
};

export default {
    INVOICE_HEADER_STATE_MAP,
    INVOICE_ITEMS_STATE_MAP,
    PREVIEW_STATE_MAP,
    DOCUMENTS_STATE_MAP,
    getInvoicePostData,
    openInBrowser,
    getInvoiceAsPng,
}

